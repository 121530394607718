<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">		
		<div class="p-col-12">
			<Toast/>
			<div class="card p-fluid">				
				<div class="p-grid">
					<h5 class="p-col">{{ infoTipo.tipo }}</h5>
					<div class="demo-container p-p-0 p-p-sm-1 p-p-md-2 p-p-lg-3">
					<Button v-if="!esNuevo" label="Descargar" icon="pi pi-download" class="p-button p-button-secondary" :loading="descargandoPDF" @click="showFormatos"/>				
					</div>
				</div>
				<Fieldset v-if="appMode == 0" class="p-m-2" legend="Cuentahabiente">
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1">							
							<label for="cuentahabiente">Cuentahabiente</label>
							<div class="p-fluid p-inputgroup">
							<InputText size="135" id="cuentahabiente" type="text" v-model="mov.cta_habiente"  @click="seleccionarCtaHabiente" disabled/>																							
							<Button icon="pi pi-search" @click="seleccionarCtaHabiente" :disabled="bloquearModificaciones"/>							
							</div>
						</div>
						<div class="p-field p-m-1">
							<label for="nombre">Nombre</label>
							<InputText size="100" id="nombre" v-model="mov.nombre" :maxlength="100" type="text" :disabled="bloquearModificaciones"/>
						</div>	
						<div class="p-field p-m-1">
							<label for="nit">NIT</label>
							<InputText size="20" id="nit" :maxlength="20"  v-model="mov.nit" type="text" :disabled="bloquearModificaciones"/>
						</div>	
						<div>
						<div class="p-field p-m-1">
							<label for="direccion">Dirección</label>
							<Dropdown v-model="mov.id_cta_habiente_direccion" :options="lkpDirecciones" optionLabel="descripcion" optionValue="id_cta_habiente_direccion" placeholder="Seleccionar" @change="cargarDireccion" :disabled="bloquearModificaciones"/>
						</div>							
						<div class="p-field p-m-1">						
							<Textarea id="direccion" v-model="mov.direccion" :maxlength="250" :autoResize="true" rows="2" cols="125" :disabled="bloquearModificaciones"/>						
						</div>
						</div>																															
					</div>	
				</Fieldset>	
				<Fieldset v-if="appMode==1" class="p-m-2" legend="Información de Inmueble">
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">							
							<label for="inmueble">Inmueble</label>
							<div class="p-inputgroup">
								<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																							
								<Button icon="pi pi-search" @click="seleccionarInmueble" :disabled="bloquearModificaciones"/>							
							</div>
						</div>
						<div class="p-field p-col-8">
							<label for="mds_nombre">Nombre</label>
							<InputText size="100" id="mds_nombre" v-model="mov.nombre" :maxlength="100" type="text" disabled/>
						</div>	
						<div class="p-field p-col-4">
							<label for="mds_nit">NIT</label>
							<InputText size="20" id="mds_nit" v-model="mov.nit" type="text" disabled/>
						</div>	
						<div class="p-field p-col-12">
							<label for="mds_direccion">Dirección</label>
							<InputText size="20" id="mds_direccion" v-model="mov.direccion" :maxlength="250" type="text" disabled/>							
						</div>							
					</div>	
				</Fieldset>					
				<div class="card">
				<!-- <SelectButton v-model="activeTab" :options="tabs" optionLabel="name" dataKey="tabIndex" :style="appMode==0)? '' : 'display: none'" />				 -->
				<SelectButton v-model="activeTab" :options="tabs" optionLabel="name" dataKey="tabIndex" />				
				<TabView v-model:activeIndex="activeTab.tabIndex">
					<TabPanel>
					<div  v-if="appMode==0" id="sucursal" class="p-fluid p-field" style="display: none">
						<label for="id_sucursal">Sucursal</label>
						<Dropdown v-model="mov.id_sucursal" :options="lkpSucursales" optionLabel="sucursal" optionValue="id_sucursal" placeholder="Sucursal..." :disabled="bloquearModificaciones"/>						
					</div>				
					<div class="p-fluid p-formgrid p-grid">
						<div v-if="appMode==0" class="p-field p-m-1">
							<label for="id_bodega">Bodega</label>
							<Dropdown v-model="mov.id_bodega" :options="lkpBodegas" optionLabel="bodega" optionValue="id_bodega" placeholder="Bodega" :disabled="bloquearModificaciones">
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span>{{slotProps.option.codigo}}-{{slotProps.option.bodega}}</span>
								</div>
							</template>							
							</Dropdown>						
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1" >
							<label for="serie">Serie</label>
							<Dropdown v-model="mov.id_movimiento_serie" :options="lkpSeries" optionLabel="serie" optionValue="id_movimiento_serie" placeholder="Serie" @change="cargarSerieCorrelativo" :disabled="!esNuevo"/>						
						</div>
						<div class="p-field p-m-1">
							<label for="no_docto">No. Documento</label>
							<InputText Type="text" v-model="documento" disabled/>
						</div>
					</div>	
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1">
							<label for="icon">Fecha</label>
							<Calendar id="fecha" v-model="mov.fecha" :showIcon="true" :manualInput="false" dateFormat="dd-MM-yy" :disabled="bloquearModificaciones"/>
						</div>		
						<div v-if="appMode==0" class="p-field p-m-1">
							<label for="empleado">Empleado</label>
							<Dropdown v-model="mov.id_empleado" :options="lkpEmpleados" optionLabel="nombreCompleto" optionValue="id_empleado" placeholder="Seleccionar" :disabled="bloquearModificaciones"/>						
						</div>											
					</div>	
					<div v-if="appMode==1" class="p-fluid p-formgrid p-grid">			
						<div class="p-field p-m-1">
							<label for="mds_fecha_pago">Fecha de Pago</label>
							<Calendar id="mds_fecha_pago" v-model="mov.fecha_pago" :showIcon="true" :manualInput="false" dateFormat="dd-MM-yy" :disabled="bloquearModificaciones"/>
						</div>	
					</div>						
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1">
							<label for="referencia">Referencia</label>
							<Textarea id="referencia" v-model="mov.referencia" :autoResize="true" :maxlength="100"  rows="2" cols="125" :disabled="bloquearModificaciones"/>
						</div>	
					</div>	
					</TabPanel>
					<TabPanel>
						<div id="empleado" class="p-fluid p-field" style="display: none">
							<label for="id_empleado">Empleado</label>
							<Dropdown v-model="mov.id_empleado" :options="lkpEmpleados" optionLabel="nombreCompleto" optionValue="id_empleado" placeholder="Empleado.." :disabled="bloquearModificaciones"/>						
						</div>				
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-m-1">
								<label for="id_metodo_envio">Método Envío</label>
								<Dropdown v-model="mov.id_metodo_envio" :options="lkpMetodosEnvio" optionLabel="metodo_envio" optionValue="id_metodo_envio" placeholder="Seleccionar" :disabled="bloquearModificaciones"/>					
							</div>
							<div class="p-field p-m-1" >
								<label for="fecha_entrega">Fecha Entrega</label>
								<Calendar id="fecha_entrega" v-model="mov.fecha_entrega" :showIcon="true" :manualInput="false" dateFormat="dd-MM-yy" :disabled="bloquearModificaciones"/>
							</div>						
						</div>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-m-1">
								<label for="id_condicion_pago">Condición de Pago</label>
								<Dropdown v-model="mov.id_condicion_pago" :options="lkpCondicionesPago" optionLabel="condicion_pago" optionValue="id_condicion_pago"  placeholder="Seleccionar" :disabled="bloquearModificaciones"/>					
							</div>						
							<div class="p-field p-m-1">
								<label for="fecha_pago">Fecha de Pago</label>
								<Calendar id="fecha_pago" v-model="mov.fecha_pago" :showIcon="true" :manualInput="false" dateFormat="dd-MM-yy" :disabled="bloquearModificaciones"/>
							</div>					
						</div>		
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-m-1">
								<label for="id_condicion_pago">Moneda</label>
								<Dropdown v-model="mov.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar" :disabled="bloquearModificaciones"/>					
							</div>						
							<div class="p-field p-m-1">
								<label for="tasa_cambio">Tasa Cambio</label>
								<InputNumber v-model="mov.tasa_cambio" mode="decimal" :minFractionDigits="2" :maxFractionDigits="4" :disabled="bloquearModificaciones"/>
							</div>					
						</div>												
					</TabPanel>
					<TabPanel>
						<div class="p-field p-grid">
							<label for="autorizacion" class="p-col-fixed" style="width:100px">Autorización</label>
							<div class="p-col">
								<InputText id="autorizacion" type="text" v-model="mov.fel.autorizacion" disabled/>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="serie" class="p-col-fixed" style="width:100px">Serie</label>
							<div class="p-col">
								<InputText id="serie" type="text" v-model="mov.fel.serie" disabled/>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="numero" class="p-col-fixed" style="width:100px">Número</label>
							<div class="p-col">
								<InputText id="numero" type="text" v-model="mov.fel.numero" disabled/>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="numero_acceso" class="p-col-fixed" style="width:100px">Número Acceso</label>
							<div class="p-col">
								<InputText id="numero_acceso" type="text" v-model="mov.fel.numero_acceso" disabled/>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="firma_fecha" class="p-col-fixed" style="width:100px">Firma</label>
							<div class="p-col">
								<InputText id="firma_fecha" type="text" v-model="mov.fel.firma_fecha" disabled/>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="certificado_fecha" class="p-col-fixed" style="width:100px">Certificación</label>
							<div class="p-col">
								<InputText id="certificado_fecha" type="text" v-model="mov.fel.certificado_fecha" disabled/>
							</div>
						</div>		
						<div class="p-field p-grid">
							<label for="certificador" class="p-col-fixed" style="width:100px">Certificador</label>
							<div class="p-col">
								<InputText id="certificador" type="text" v-model="mov.fel.certificador" disabled/>
							</div>
						</div>								
					</TabPanel>
				</TabView>	
				</div>			
				<Toolbar>
					<template #left>						
						<div v-if="!bloquearModificaciones&&infoTipo.puedeCrearNuevoSinOrigen" class="p-inputgroup">
							<InputText size="100" id="codigo" placeholder="Ingrese el código o descripción del producto a buscar..." type="text" v-model="filtroProducto" v-on:keyup.enter="buscarProducto" :disable="bloquearModificaciones"/>				
							<Button icon="pi pi-search" @click="seleccionarProducto" :disabled="bloquearModificaciones"/>							
						</div>	
					</template>
					<template #right>						
						<div v-if="mostrarSelOrigen && !bloquearModificaciones">
							<Button icon="pi-ellipsis-h" class="p-button-rounded p-button-secondary" @click="seleccionarOrigen"/>
						</div>
					</template>				
				</Toolbar>
				<DataTable :value="mov.detalle" class="p-datatable-sm" editMode="cell" @cell-edit-complete="onCellEditComplete" :scrollable="true" scrollHeight="flex" selectionMode="single" dataKey="id_movimiento_det">
				<Column field="codigo" header="Código" :sortable="true" style="min-width:200px"></Column>
				<Column field="producto" header="Producto" :sortable="true" class="p-text-nowrap p-text-truncate" style="min-width:200px">
					<template #body="slotProps">
						<div v-tooltip.top="slotProps.data.producto">
							{{ slotProps.data.producto }}
						</div>																
					</template>				
				</Column>
				<!-- <Column field="id_unidad_mov" header="U.Medida" :style="appMode==0? '' : 'display: none'"></Column> -->
				<Column field="cantidad" header="Cantidad" :sortable="true">	
                    <template v-if="!bloquearModificaciones" #editor="{ data, field }">
						<InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2"/>						
                        <!-- <InputText v-model="data[field]"  autofocus /> -->
                    </template>				
				</Column>				
				<Column field="precio_unitario" :header="tituloPrecio" :sortable="true">
					<template #body="{data}">
						{{formatCurrency(data.precio_unitario)}}
					</template>
                    <template v-if="!bloquearModificaciones" #editor="{ data, field }">
                        <InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2" autofocus />
                    </template>							
				</Column>				
				<Column field="sub_total" header="Subtotal"  :sortable="true" dataType="numeric" :style="appMode==0? '' : 'display: none'">
					<template #body="{data}">
						{{formatCurrency(data.sub_total)}}
					</template>
				</Column>	
				<Column field="descuento" header="Descuento" :sortable="true" dataType="numeric" :style="appMode==0? '' : 'display: none'">
					<template #body="{data}">
						{{formatCurrency(data.descuento)}}
					</template>
                    <template v-if="!bloquearModificaciones" #editor="{ data, field }">
                        <InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2" autofocus />
                    </template>								
				</Column>	
				<Column field="pct_descuento" header="% Descuento"  :sortable="true" dataType="numeric" :style="appMode==0? '' : 'display: none'">
					<template #body="{data}">
						{{ data.pct_descuento }}
					</template>						
				</Column>																	
				<Column field="total" header="Total" :sortable="true" dataType="numeric" style="">
					<template #body="{data}">
						{{formatCurrency(data.total)}}
					</template>
				</Column>				
				<Column>
					<template v-if="!bloquearModificaciones" #body="slotProps">							
						<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="removerItem(slotProps.data)"/>
					</template>
				</Column>									
				<ColumnGroup type="footer">
					<Row>
						<Column footer="Artículos:" :colspan="1" footerStyle="text-align:right" />
						<Column :footer="mov.detalle.length"/>
						<Column footer="Subtotal:" :colspan="3" footerStyle="text-align:right" :style="appMode==0? '' : 'display: none'"/>
						<Column :footer="formatCurrency(mov.sub_total)" :style="appMode==0? '' : 'display: none'"/>
						<Column footer="Descuento:" :colspan="3" footerStyle="text-align:right" :style="appMode==0? '' : 'display: none'"/>
						<Column :footer="formatCurrency(mov.descuento)" :style="appMode==0? '' : 'display: none'"/>						
						<Column footer="Total:" :colspan="3" footerStyle="text-align:right" />
						<Column :footer="formatCurrency(mov.total)" footerStyle="text-align:left"/>			
					</Row>
				</ColumnGroup>							
				</DataTable>					
				<Button label="Registrar" @click="registrarMov" icon="pi pi-check" :disabled="deshabilitarRegistro"/>				
			</div>
		</div>

		<BuscarCtaHabiente :key="dlgBuscarCtaHabienteRefresh" :mostrar="ctaHabienteDialog" @cancelar="cerrarDlgCtahabiente"  @seleccionar="elegirCtaHabiente"/>
		<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
		<BuscarProducto ref="dlgBuscaProducto" :mostrar="productoDialog" @cancelar="cerrarDlgProducto"  @seleccionar="elegirProducto" :id_lista="selCtaHabiente.id_producto_lista" :filtro="filtroProducto" :key="dlgBuscarProductoRefresh"/>
		<BuscarMovOrigen :mostrar="origenDialog" :id_cta_habiente_info="mov.id_cta_habiente_info" :id_moneda="mov.id_moneda" :titulo="tituloTrx" :id_movimiento_tipo="infoTipo.id_movimiento_tipo" @cancelar="cerrarDlgOrigen" :key="reloadOrigen" @seleccionar="elegirOrigen"/>
		<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px" :breakpoints="{'960px': '75vw'}">
			<DataTable :value="infoTipo.fmts" v-model:selection="selFormato" selectionMode="single" :paginator="false" :rows="5" @rowSelect="onFormatoSelect" responsiveLayout="scroll" >
				<Column field="nombre" header="Seleccionar Formato" sortable style="width: 50%">
					<template #body="{data}">
						<i v-if="data.referencia!='FEL-XML'" class="pi pi-file-pdf" style="font-size: 1rem; color: red"></i>
						<i v-else class="pi pi-file" style="font-size: 1rem; color: blue"></i>
						{{ data.nombre }}
					</template>						
				</Column>
			</DataTable>
		</OverlayPanel>

		<ConfirmacionEnvioEmails :key="dlgConfirmarEmailsRefresh" :destinatarios="emails" :mostrar="dlgEnvioEmail" @cancelar="cancelarEnvioXEmail"  @confirmacion="enviarEmailTrx"/>

        <Dialog v-model:visible="dlgValidacion" :style="{width: '50vw'}" :modal="true">
			<template #header>
				<i class="pi pi-info-circle" style="fontSize: 2rem"></i>
				<p class="p-m-0">{{ headerValidacion }}</p>
			</template>
            <p class="p-m-0">{{ mensajeValidacion }}</p>
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" @click="cerrarDlgValidacion" autofocus />
            </template>
        </Dialog>			
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import BuscarCtaHabiente from '../components/BuscarCtaHabiente.vue';
import ConfirmacionEnvioEmails from '../components/ConfirmacionEnvioEmails.vue';
import MdsBuscarInmueble from '../components/MdsBuscarInmueble.vue';
import BuscarProducto from '../components/BuscarProducto.vue';
import BuscarMovOrigen from '../components/BuscarMovOrigen.vue';
import InvService from '../service/inv.service';
import GrlService from '../service/grl.service';
import { CURRENT_APP_MODE } from '../service/constants';
import { uuid } from 'vue-uuid'; 

export default {
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			puedeEnviarEmail: (this.$store.getters['auth/tieneAcceso']('VF_IMPR_TRX')),
			puedeImprimir: false,
            activeTab : {name: "Documento", tabIndex: 0},
			tabs: [
				{name: "Documento", tabIndex: 0},
				{name: "Adicional", tabIndex: 1},
				{name: "FEL", tabIndex: 2}
			],
			editingCellRows: {},
            editingRows: [],			
			headerValidacion: "",
			mensajeValidacion: "",
			dlgValidacion: false,
			dlgEnvioEmail: false,
			dlgConfirmarEmailsRefresh: 0,
			autoaplicar: false,
			bloquearModificaciones: true,
			tituloPrecio: (CURRENT_APP_MODE == 1? 'Valor Unitario': 'Precio Unitario'),
			selCtaHabiente: {
				id_producto_lista: ''
			},
			ctaHabienteDialog: false,
			dlgBuscarCtaHabienteRefresh: 0,
			ctaHabienteDialogLoading: false,
			inmuebleDialog: false,
			selInmueble: null,
			InmuebleDisplay: 'Seleccionar...',
			productoDialog: false,
			dlgBuscarProductoRefresh: 0,
			filtroProducto: '',
			origenDialog: false,	
			reloadOrigen: 0,		
			infoTipo: {
				id_movimiento_tipo: '',
				tiene_origen: false,
				fct_inventario: 0,
				tipo: '',
				puedeCrearNuevoSinOrigen: false,
				fmts: []},
			esFel: false,
			selFormato: null,		
			filtroBusquedaCtaHabiente: "",
			trx: this.$store.state.auth.currentAppCtx.trxs.find(t => t.tipo == this.tipo.toUpperCase()),
			lkpSucursales: this.$store.state.auth.currentAppCtx.Sucursales,
			lkpBodegas: this.$store.state.auth.currentAppCtx.Bodegas,
			// lkpMovimientosTipo: this.$store.state.auth.currentAppCtx.tiposMovimientoCxc.filter(tipo => tipo.requiere_pago == 'S'),
			lkpCtaHabientes: [],
			lkpSeries: null,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			lkpEmpleados: this.$store.state.auth.currentAppCtx.Empleados,
			lkpMetodosEnvio: this.$store.state.auth.currentAppCtx.MetodosEnvio,
			lkpCondicionesPago: [],
			lkpDirecciones: [],
			tasaIva: parseFloat(this.$store.state.auth.currentAppCtx.Parametros.find(p => p.parametro == 'IVA').valor) / 100,
			loading: false,		
			descargandoPDF: false,	
			tituloTrx: this.titulo,
			mov: {
				id_movimiento: '',
				id_cta_habiente_info: '',
				fel: {
					autorizacion: "",
					serie: "",
					numero: "",
					numero_acceso: "",
					firma_fecha: "",
					certificador: "",
					certificado_fecha: ""					
				},
				no_docto: 0,
				sub_total: 0.0,
				pct_descuento: 0.0,
				descuento: 0.0,
				total: 0.0,
				pct_comision: 0.0,
				atributos: [],
				detalle: [],
				pagos: []
			},
			emails: []
		}
	},
	props: {
		tipo: {
			type: String,
			required: true,			
		},
		id: {
			type: String,
			required: false,
			default: ''
		},
		titulo: {
			type: String,
			required: false,
			default: ''			
		}
	},	
	components: { BuscarCtaHabiente, MdsBuscarInmueble, BuscarProducto, ConfirmacionEnvioEmails, BuscarMovOrigen, Loading},	
	mounted()
	{
		this.GetInfoTipo();		
	},
	watch:{
		"mov.id_condicion_pago"(val) {
			this.actualizarFechaPago();
		}
	},
	computed:
	{
		documento()
		{
			return this.mov.no_docto.toString().padStart(6, '0');
		},
		deshabilitarRegistro() {
			var registroValido = ((this.mov.id_cta_habiente_info != '') && (this.mov.total > 0.00) && (this.mov.cerrado == 'N') && (this.mov.anulado == 'N'));

			return (!registroValido || this.bloquearModificaciones);
		},
		esNuevo()
		{
			return (this.mov.id_movimiento == '');
		},
		esCerrado()
		{
			return (this.mov.cerrado == 'S');
		},
		esAnulado()
		{
			return (this.mov.anulado == 'S');
		},
		ctaHabienteSelected() {
			return (this.mov.id_cta_habiente_info != '');
		},
		mostrarSelOrigen()
		{
			// return ((this.mov.id_cta_habiente_info != '') && (this.infoTipo.tiene_origen));
			return ((this.mov.id_cta_habiente_info != ''));
		}			
	},
	methods: {
		formatDateToSrv(fecha)
		{
			return new Date(fecha.getTime() - (fecha.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		//    return parseFloat(value).toFixed(2).toLocaleString('en-US', {style: 'currency', currency: this.mov.id_moneda});
		},
		showDlgValidacion(titulo, mensaje){		
			this.headerValidacion = titulo;	
			this.mensajeValidacion = mensaje;
			this.dlgValidacion = true;
		},
		cerrarDlgValidacion() {
			this.mensajeValidacion = "";
			this.dlgValidacion = false;
		},
		newRec()
		{
			this.editingCellRows = {};
			this.editingRows = [];	

			var rec = {
				id_movimiento: '',
				id_cta_habiente_info: '',
				id_cta_habiente_direccion: '',
				id_empresa: this.infoTipo.id_empresa,
				id_sucursal: this.lkpSucursales[0].id_sucursal,
				id_bodega: this.lkpBodegas[0].id_bodega,
				id_movimiento_tipo: this.infoTipo.id_movimiento_tipo,
				id_condicion_pago: null,
				id_movimiento_serie: this.infoTipo.series[0].id_movimiento_serie,
				id_metodo_envio: this.lkpMetodosEnvio[0].id_metodo_envio,
				id_empleado: this.$store.state.auth.currentAppCtx.id_empleado,
				id_moneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,	
				id_caja_jornada: '',
				serie: '',
				referencia: '',
				nit: '',
				cta_habiente: '',
				nombre: '',
				direccion: '',
				usuario: '',
				no_docto: 0,
				sub_total: 0.0,
				pct_descuento: 0.0,
				descuento: 0.0,
				total: 0.0,
				pct_comision: 0.0,
				exento: 0.0,
				exonerado: 0.0,
				tasa_cambio: 1.0,
				tasa_mora: 0.0,
				tasa_iva: this.tasaIva,
				cerrado: 'N',
				anulado: 'N',
				fecha: new Date(),
				fecha_pago: new Date(),
				fecha_entrega: new Date(),
				fel: {
					autorizacion: "",
					serie: "",
					numero: "",
					numero_acceso: "",
					firma_fecha: "",
					certificador: "",
					certificado_fecha: ""					
				},
				atributos: [],				
				detalle: [],
				pagos: []
			};
			
			this.selInmueble = null;
			this.InmuebleDisplay = 'Seleccionar...';
			this.reevaluarBloqueoModificaciones();

			return rec;
		},		
		reevaluarBloqueoModificaciones()
		{
			this.bloquearModificaciones = (this.mov.id_movimiento != '') && ((this.infoTipo.fct_inventario != 0) || (this.infoTipo.es_fel == 'S'));			
		},
		cargarRec(id)
		{
			this.loading = true;
			InvService.getTrx(this.$store.state.auth.currentAppCtx, id).then(data => {
				this.mov =  data;
				
				if(this.appMode == 1){
					this.InmuebleDisplay = data.inmueble;
				}				
				this.reevaluarBloqueoModificaciones();
				this.loading = false;
			}).catch(err => {
				this.infoTipo = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
			);	
		},
		removerItem(Item) {
			this.mov.detalle = this.mov.detalle.filter(val => val.id_movimiento_det !== Item.id_movimiento_det);
			this.recalcularTotales();
		},
		GetInfoTipo() {
			this.loading = true;
			InvService.getInfoMovTipo(this.$store.state.auth.currentAppCtx, this.trx.tipo).then(data => {
				this.infoTipo = data;

				this.puedeImprimir = (this.$store.getters['auth/tieneAcceso']('VF_IMPR_TRX')) && (this.infoTipo.fmts.length > 0);	
				this.infoTipo.puedeCrearNuevoSinOrigen = (this.infoTipo.crear_sin_origen == 'S');
				this.esFel =  (this.infoTipo.es_fel=="S");
				this.lkpSeries = data.series;												
				
				if(this.id != '')
				{
					this.cargarRec(this.id);
				} else {
					this.mov = this.newRec();	
					this.bloquearModificaciones = false;
				}	
				
				let felHabilitado = this.$store.getters['auth/felHabilitado'];

				if(!this.esFel || !felHabilitado) {
					this.tabs.splice(2, 1);					
				}	

				this.loading = false;
				this.cargarSerieCorrelativo();	

			}).catch(err => {
				this.infoTipo = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})				
			}
			);	
		},
		cargarSerieCorrelativo()
		{
			let serie = this.lkpSeries.find(s=> s.id_movimiento_serie == this.mov.id_movimiento_serie)
			
			if(serie){
				this.mov.id_movimiento_serie = serie.id_movimiento_serie;
				this.mov.no_docto = serie.proximo;			
			}
		},
		cargarDireccion()
		{
			let dir = this.lkpDirecciones.find(d=> d.id_cta_habiente_direccion == this.mov.id_cta_habiente_direccion)
			
			if((dir) && (this.appMode == 0)){
				this.mov.direccion = dir.direccion;		
			}
		},
		actualizarFechaPago()
		{
			let cp = this.lkpCondicionesPago.find(c=> c.id_condicion_pago== this.mov.id_condicion_pago)
						
			if((cp) && (this.appMode == 0)){
				var fechaPago = new Date();
    			fechaPago.setDate(this.mov.fecha.getDate() + cp.dias);
				this.mov.fecha_pago = fechaPago;		
			}
		},
		seleccionarCtaHabiente() {
			this.ctaHabienteDialog = true;
			this.dlgBuscarCtaHabienteRefresh++;
		},	
		cerrarDlgCtahabiente(){
			this.ctaHabienteDialog = false;
		},
		async elegirCtaHabiente(CtaHabiente){
			this.loading = true;
			this.ctaHabienteDialog = false;
			try {
				if(!CtaHabiente.id_producto_lista){
					CtaHabiente.id_producto_lista = '';
				}
				
				this.selCtaHabiente = CtaHabiente;

				this.lkpCondicionesPago = CtaHabiente.condicionesPago;

				var data = await GrlService.getCtaHabienteDirecciones(this.$store.state.auth.currentAppCtx, CtaHabiente.id_cta_habiente_info);
				this.lkpDirecciones = data;

				this.mov.id_cta_habiente_info = CtaHabiente.id_cta_habiente_info;
				
				if(this.lkpCondicionesPago.length > 1) {
					this.mov.id_condicion_pago = this.lkpCondicionesPago[1].id_condicion_pago;
				} else {
					this.mov.id_condicion_pago = this.lkpCondicionesPago[0].id_condicion_pago;
				}				
				
				this.mov.cta_habiente = CtaHabiente.nombre_comercial,
				this.mov.nombre = CtaHabiente.nombre_documento;
				this.mov.nit = CtaHabiente.nit;
				this.mov.direccion = CtaHabiente.direccion_documento;

				if(this.lkpDirecciones.length > 0){
					this.mov.id_cta_habiente_direccion =  this.lkpDirecciones[0].id_cta_habiente_direccion;
					this.cargarDireccion();
				}
				
			} catch (err) {
				this.loading = false;
				this.ctaHabienteDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err});
			} finally {
				this.loading = false;
			}
		},
		seleccionarInmueble() {
			this.inmuebleDialog = true;
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		async elegirInmueble(Inmueble){
			this.loading = true;
			try {
				this.selInmueble = Inmueble;
				this.inmuebleDialog = false;

				this.InmuebleDisplay = Inmueble.display;
				this.selCtaHabiente.id_producto_lista = '';

				var data = await GrlService.getCtaHabienteDirecciones(this.$store.state.auth.currentAppCtx, Inmueble.id_cta_habiente_info);
				this.lkpDirecciones = data;

				this.mov.id_cta_habiente_info = Inmueble.id_cta_habiente_info;
				this.mov.nombre = Inmueble.nombre_documento;
				this.mov.nit = Inmueble.nit_documento;
				this.mov.direccion = Inmueble.direccion_documento;
				this.lkpCondicionesPago = Inmueble.condicionesPago;
				if(this.lkpCondicionesPago.length > 1) {
					this.mov.id_condicion_pago = this.lkpCondicionesPago[1].id_condicion_pago;
				} else {
					this.mov.id_condicion_pago = this.lkpCondicionesPago[0].id_condicion_pago;
				}

				if(this.lkpDirecciones.length > 0) {
					this.mov.id_cta_habiente_direccion =  this.lkpDirecciones[0].id_cta_habiente_direccion;
					this.cargarDireccion();
				}
				this.loading = false;
			} catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			} finally {
				this.loading = false;
			}

		},
		buscarProducto()
		{
			// AppCtx, filtro, mostrarConversiones, tipoProducto, idLista, mostrarMateriaPrima
			InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroProducto, 'S', '', this.selCtaHabiente.id_producto_lista, 'S').then(data => 
			{
				if(data.length == 1){
					data[0].mostrar = 'S';
					this.elegirProducto(data[0]);	
				} else {							
					this.$refs.dlgBuscaProducto.mostrarResultadosBusqueda(this.filtroProducto, data);							
				}
				this.productoDialogLoading = false;
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
		},
		seleccionarProducto(){
			if(this.filtroProducto == '') {
				this.productoDialog = true;
				this.dlgBuscarProductoRefresh++;
			} else {
				this.buscarProducto();
			}
		},
		cerrarDlgProducto(){
			this.filtroProducto = '';
			this.productoDialog = false;
		},		
		elegirProducto(Producto){
			this.productoDialog = false;	
			this.filtroProducto = '';
			
			this.loading = true;
			InvService.getPrecioProducto(this.$store.state.auth.currentAppCtx, Producto.id_producto_empresa, this.selCtaHabiente.id_cta_habiente_info, Producto.id_unidad_conversion).then(data => 
			{
				this.loading = false;
				let cantidad = 1;
				this.mov.detalle.push(
				{
					id_movimiento_det: uuid.v4(),
					id_movimiento_det_aplica: "",
					id_producto_empresa: Producto.id_producto_empresa,
					id_movimiento: this.mov.id_movimiento,
					id_ubicacion: this.lkpBodegas.find(b => b.id_bodega == this.mov.id_bodega).id_ubicacion_default,
					id_precio_tipo: "",
					id_precio_lista: "",
					id_unidad_base: Producto.id_unidad_base,
					id_unidad_mov: Producto.id_unidad_conversion,
					codigo: Producto.codigo,
					producto: this.appMode==0?Producto.producto:Producto.descripcion_simple,
					descripcion: Producto.descripcion,
					mostrar: Producto.mostrar,
					fct_conversion: Producto.factor,
					cantidad: cantidad,
					aplicado: 0.0,
					saldo: 0.0,
					sub_total: Producto.precio_unitario * cantidad,
					pct_descuento: 0.0,
					descuento: 0.0,
					total: Producto.precio_unitario * cantidad,
					precio_unitario: data.precio,
					costo_unitario: Producto.costo_unitario,
					pct_comision: 0.0,
					comision: 0.0,
					cantidad_mov: (cantidad / Producto.factor)
					}
				);
				this.recalcularTotales();
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
	},
	seleccionarOrigen(){
		this.reloadOrigen += 1;
		this.origenDialog = true;
	},
	cerrarDlgOrigen(){
		this.origenDialog = false;
	},					
	elegirOrigen(MovOrigen){
		this.origenDialog = false;	
		
		let detalle;
		for(let i=0; i < MovOrigen.detalle.length; i++)
		{
			detalle = MovOrigen.detalle[i];		
			
			//Si ya existe esta referencia borre antes de cargarla de nuevo
			this.mov.detalle = this.mov.detalle.filter(val => val.id_movimiento_det_aplica !== detalle.id_movimiento_det);
			
			if(MovOrigen.anulado=='S') {
				detalle.id_movimiento_det = null;
			};

			this.mov.detalle.push(
				{
					id_movimiento_det: uuid.v4(),
					id_movimiento_det_aplica: detalle.id_movimiento_det,
					id_producto_empresa: detalle.id_producto_empresa,
					id_movimiento: this.mov.id_movimiento,
					id_ubicacion: detalle.id_ubicacion,
					id_precio_tipo: detalle.id_precio_tipo,
					id_precio_lista: detalle.id_precio_lista,
					id_unidad_base: detalle.id_unidad_base,
					id_unidad_mov: detalle.id_unidad_mov,
					codigo: detalle.codigo,
					producto: detalle.producto,
					descripcion: detalle.descripcion,
					mostrar: detalle.mostrar,
					fct_conversion: detalle.fct_conversion,
					cantidad: (detalle.saldo * detalle.fct_conversion),
					aplicado: 0.0,
					saldo: detalle.saldo,
					sub_total: detalle.sub_total,
					pct_descuento: detalle.pct_descuento,
					descuento: detalle.descuento,
					total: detalle.total,
					precio_unitario: detalle.precio_unitario,
					costo_unitario: detalle.costo_unitario,
					pct_comision: 0,
					comision: 0.0,
					cantidad_mov: detalle.saldo
					}
				);
			}
			this.recalcularTotales();
		},
		recalcularTotales(){
			this.mov.sub_total = 0;
			this.mov.descuento = 0;
			this.mov.total = 0;

			let i;
			let pctDescuento = 0;
			for (i = 0; i < this.mov.detalle.length; i++) {
				this.mov.detalle[i].sub_total = parseFloat(this.mov.detalle[i].cantidad * this.mov.detalle[i].precio_unitario);
				pctDescuento = (this.mov.detalle[i].descuento * 100) / this.mov.detalle[i].sub_total;
				this.mov.detalle[i].pct_descuento = Math.round(pctDescuento * 100) / 100;
				this.mov.detalle[i].total = this.mov.detalle[i].sub_total - this.mov.detalle[i].descuento;

				this.mov.sub_total += this.mov.detalle[i].sub_total;
				this.mov.descuento += this.mov.detalle[i].descuento;				
				this.mov.total += this.mov.detalle[i].total;					
			}
		},	
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'cantidad':
					if (this.isPositiveNumeric(newValue)){                        
						data[field] = newValue;
						data['cantidad_mov'] = newValue / data['fct_conversion'];
						this.recalcularTotales();
					}
					else
						event.preventDefault();
					break;					
                case 'precio_unitario':		
					if (this.isPositiveNumeric(newValue)){ 
						data[field] = newValue; 
						this.recalcularTotales();
					}
					else
						event.preventDefault();
					break;								
				case 'descuento':
					if (this.isPositiveNumeric(newValue)){ 
						data[field] = newValue; 
						this.recalcularTotales();
					}
					else
						event.preventDefault();
					break;		
                default:
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;
            }
        },		
        onCellEdit(newValue, props) {
            if (!this.editingCellRows[props.index]) {
                this.editingCellRows[props.index] = {...props.data};
            }

            this.editingCellRows[props.index][props.column.props.field] = newValue;
        },
        isPositiveNumeric(val) {
			if(this.isNumeric(val)){
				let num = parseFloat(val);
				return num >= 0.00;
			} else {
				return false;
			}
		},	
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},	
		showFormatos(event) {
            this.$refs.op.toggle(event);
        },
		onFormatoSelect(event)
		{
			this.$refs.op.toggle(event);
			if(this.selFormato.referencia == "FEL-XML") {
				this.descargarFELXml();
			} else {
				this.descargarPDF(this.mov, this.selFormato.referencia);
			}
		},		
		descargarPDF(movimiento, referencia) {	
			this.loading = true;
			InvService.getFormatoImpresionTrx(this.$store.state.auth.currentAppCtx, movimiento, referencia, false).then(
				response => {
					this.loading = false;
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					if(this.infoTipo.es_fel == 'N'){
						link.download = `${movimiento.nombre}(${movimiento.serie}-${movimiento.no_docto}).pdf`;
					} else {
						link.download = `${movimiento.nombre}(${movimiento.fel.serie}-${movimiento.fel.numero}).pdf`;
					}
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.loading =  false;					
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)
		},
		descargarFELXml() {
			this.loading = true;
			var movimiento = this.mov;
			InvService.getTrxFELXml(this.$store.state.auth.currentAppCtx, movimiento.id_movimiento).then(
				response => {
					this.loading = false;
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/xml'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					if(this.infoTipo.es_fel == 'N'){
						link.download = `${movimiento.nombre}(${movimiento.serie}-${movimiento.no_docto}).xml`;
					} else {
						link.download = `${movimiento.nombre}(${movimiento.fel.serie}-${movimiento.fel.numero}).xml`;
					}
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.loading =  false;					
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)
		},
		confirmarEnvioXEmail(Destinatarios) {
			this.emails = Destinatarios;
			this.dlgConfirmarEmailsRefresh++;
			this.dlgEnvioEmail = true;			
		},
		async cancelarEnvioXEmail() {
			this.dlgEnvioEmail = false;
			this.loading = true;
			try {
				await this.nuevoMov();
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},
		async enviarEmailTrx(Emails) {
			this.dlgEnvioEmail = false;
			this.loading = true;
			try {				
				var notif = {						
					id: this.mov.id_movimiento,
					emails: Emails
				};

				await InvService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);

				await this.nuevoMov();

				this.loading = false;
				
				this.dlgEnvioEmail = false;
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}			
		},
		async nuevoMov() {
			if(this.infoTipo.empezar_nuevo == 'S')
			{
				var series = await InvService.getSeriesTipoMovimiento(this.$store.state.auth.currentAppCtx, this.infoTipo.id_movimiento_tipo_cat);

				this.infoTipo.series = series;
				this.lkpSeries = series;
				
				this.mov = this.newRec();	
				this.cargarSerieCorrelativo();	

				this.reevaluarBloqueoModificaciones();
			}
		},
		async registrarMov() {
			this.loading = true;
			
			try {
				this.mov.serie = this.lkpSeries.find(s => s.id_movimiento_serie == this.mov.id_movimiento_serie).serie;		

				var data = await InvService.registrarMovimiento(this.$store.state.auth.currentAppCtx, this.mov);
				this.mov.id_movimiento = data.id;
				this.mov.documento = data.documento;
				this.mov.fel = data.fel;
				
				if((this.infoTipo.fmts.length > 0) && (this.infoTipo.imprimir_guardar == 'S')){
					this.descargarPDF(this.mov, this.infoTipo.fmts[0].referencia);
				}	

				this.loading = false;
				
				this.$toast.add({severity:'success', summary: 'Éxito', detail: 'El movimiento ha sido registrado con éxito!', life: 3000});									

				var enviarNotificacion = (this.selInmueble?this.selInmueble.enviar_notificacion: true);

				/* 
				Si hay un inmueble, es decir que es modo Multidomus, confirmar si tiene preferencia de envio de emails,
				si es modo Merkantus no hay inmueble si siempre se debe pedir confirmacion para envio de emails
				*/
				if(this.puedeEnviarEmail && enviarNotificacion) {
					if(this.selInmueble) {
						this.confirmarEnvioXEmail(this.selInmueble.emails);
					} else {
						this.confirmarEnvioXEmail(this.selCtaHabiente.emails);
					}
				} else {
					this.loading = true;
					await this.nuevoMov();				

					this.loading = false;
				}				
			} catch (error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error})
			}			
		}
	},
}
</script>

<style scoped>

</style>